//import React, { useState } from 'react';
import React from 'react';
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'


import BlogGames2425 from '../components/BlogGames2425'
import BlogRecentGame from '../components/BlogRecentGame'

export const IndexPageTemplate = ({ image, title, description, content, contentComponent }) => {
  //const PageContent = contentComponent || Content

 
  return (


    <div className="center-this">
      
      

      
      <section className="mystuff inside-lg padding-top">
        <div>
        
        <h2>Latest Game</h2>
          
        </div>
      </section>

      <BlogRecentGame />

      <section className="mystuff inside-lg no-padd">
        <div>
        
        <h1>{title}</h1>
          
        </div>
      </section>


      <BlogGames2425 />

      
     

    </div>


  )
}





IndexPageTemplate.propTypes = {
  //image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  //topimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  description: PropTypes.string,
  //content: PropTypes.string,
  //contentComponent: PropTypes.func,
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  //const { markdownRemark: post } = data
  return (
    <Layout>
      <IndexPageTemplate
        //image={frontmatter.image}
        //topimage={frontmatter.topimage}
        title={frontmatter.title}
        description={frontmatter.description}
        //contentComponent={HTMLContent}
        //content={post.html}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
