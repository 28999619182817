import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
//import PreviewCompatibleImage from './PreviewCompatibleImage'
import ReactPlayer from 'react-player/vimeo'

class BlogGames2425 extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className='inside-xl padding-top-bottom'>
      <div className="auto-grid">

        
      {posts && posts.length > 0 ? (
  posts.map(({ node: post }) => (
    <div className="ind-game" key={post.id}>
      <article
        className={`blog-list-item tile is-child box notification ${
          post.frontmatter.featuredpost ? 'is-featured' : ''
        }`}
      >
        <header>
          {post.frontmatter.featuredimage ? (
            <div className="featured-thumbnail">
              <Link
                className="title has-text-primary is-size-4"
                to={post.fields.slug}
              >
                <ReactPlayer
                  className="nickPlayer"
                  url={`https://vimeo.com/${post.frontmatter.videoid}/`}
                />
              </Link>
            </div>
          ) : null}
          <h2 className="post-meta">
            <Link
              className="title has-text-primary is-size-4"
              to={post.fields.slug}
            >
              {post.frontmatter.title}
            </Link>
          </h2>
          <span className="subtitle is-size-5 is-block">
            {post.frontmatter.gamedate}
          </span>
        </header>
      </article>
    </div>
  ))
) : (
  <h2>No games yet this season.</h2>
)}

      </div>
      </div>
    )
  }
}

BlogGames2425.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogGames2425Query {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {frontmatter: {templateKey: {eq: "blog-post-video"}, years: {eq: "2024-2025"} } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                gamedate(formatString: "MMMM DD, YYYY")
                featuredpost
                videoid
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 690, quality: 60) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogGames2425 data={data} count={count} />}
  />
)
