import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ReactPlayer from 'react-player/vimeo'

class BlogRecentGame extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <div className='inside-xl padding-top-bottom'>
      

        
        {posts &&
          posts.map(({ node: post }) => (
            <div className="ind-game" key={post.id}>
              <article>
                <header>

                {post.frontmatter.featuredimage ? (
                  <div className="nickPlayer">
                    
                    <ReactPlayer controls className='nickPlayer' url={`https://vimeo.com/${post.frontmatter.videoid}/`} />

                  </div>
                ) : null}
                  
                  
                </header>
                
              </article>
            </div>
          ))}

      </div>
      
    )
  }
}

BlogRecentGame.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRecentGameQuery {
        allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___gamedate]}, filter: {frontmatter: {templateKey: {eq: "blog-post-video"}}}, limit: 1) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                gamedate(formatString: "MMMM DD, YYYY")
                videoid
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 690, quality: 60) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRecentGame data={data} count={count} />}
  />
)
